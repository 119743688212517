<template>
  <v-menu
    ref="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="700px"
    min-width="700px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        ref="timeTextField"
        :value="timeRangeText"
        :placeholder="isPickerDisabled ? 'Select Date First' : 'Select Time Range'"
        :disabled="isPickerDisabled"
        readonly
        solo
        clearable
        hide-details
        v-bind="attrs"
        v-on="on"
        @click:clear="fromTime = null; untilTime = null"
      />
    </template>

    <v-container
      class="dropdown-menu"
    >
      <v-row
        justify="space-around"
        align="center"
      >
        <v-col>
          <h3 class="pb-2">
            From:
            {{ dates ? dates[0] : null }}
          </h3>
          <v-time-picker
            ref="pickerFrom"
            class="time-picker"
            v-model="fromTime"
            :max="sameDaySelected ? untilTime : null"
            format="24hr"
            @click:hour="closeFromPicker"
          />
        </v-col>

        <v-col>
          <h3 class="pb-2">
            Until:
            {{ dates ? dates[1] : null }}
          </h3>
          <v-time-picker
            ref="pickerUntil"
            v-model="untilTime"
            :min="sameDaySelected ? fromTime : null"
            format="24hr"
            @click:hour="closeUntilPicker"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-menu>
</template>

<script>
export default {
  props: {
    dates: {
      type: Array,
      default() {
        return []
      },
    },

    sameDaySelected: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      fromTime: null,
      untilTime: null,
    }
  },

  computed: {
    timeRangeText() {
      if (this.dates != null) {
        if (this.fromTime && this.untilTime && this.dates.length > 1) {
          const [dateFrom, dateUntil] = this.dates

          return `(${dateFrom}) ${this.fromTime} ~ (${dateUntil}) ${this.untilTime}`
        }
      }

      return ''
    },

    isPickerDisabled() {
      if (this.dates == null) {
        return true
      }

      return this.dates.length <= 1
    },
  },

  watch: {
    fromTime: {
      handler() {
        this.selectedTimeChanged()
      },
    },

    untilTime: {
      handler() {
        this.selectedTimeChanged()
      },
    },
  },

  methods: {
    selectedTimeChanged() {
      let times = []

      if (this.fromTime && this.untilTime) times = [this.fromTime, this.untilTime]

      this.$emit('timesSelected', times)
    },

    // Disable minute picking - set minutes to :00
    closeFromPicker(v) {
      this.$nextTick(() => {
        this.$refs.pickerFrom.selectingHour = true
      })

      const formattedHour = String(v).padStart(2, '0')

      this.fromTime = `${formattedHour}:00`
    },

    closeUntilPicker(v) {
      this.$nextTick(() => {
        this.$refs.pickerUntil.selectingHour = true
      })

      const formattedHour = String(v).padStart(2, '0')

      this.untilTime = `${formattedHour}:00`
    },
  },
}
</script>

<style>
.custom-time-picker .v-time-picker-title {
  pointer-events: none !important;
}

.dropdown-menu {
  background: #fff !important;
}

.theme--dark .dropdown-menu {
  background: #2d2d2d !important;
  color: #fff !important;
}

.theme--dark.v-picker__body  {
  background: 'red' !important;
}
</style>
