<template>
  <l-map
    ref="map"
    class="map-styling"
    style="height: 800px; width: 100%"
    :zoom="zoom"
    :center="center"
    :options="leafletOptions"
    @update:zoom="zoomUpdated"
    @update:center="centerUpdated"
    @update:bounds="boundsUpdated"
  >
    <l-tile-layer
      :url="$tileService.url"
      :attribution="$tileService.attribution"
    ></l-tile-layer>

    <l-control position="topright">
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            fab
            x-small
            class="mb-3 p-0 rectangle-color"
            :color="isDrawing ? 'error' : '#2d2d2d'"
            elevation="4"
            @click="toggleDraw"
            v-on="on"
          >
            <v-icon dense>
              {{ icons.mdiRectangle }}
            </v-icon>
          </v-btn>
        </template>
        <div>
          <span v-if="!isDrawing">Draw a rectangle</span>
          <span v-else>Cancel drawing</span>
        </div>
      </v-tooltip>
    </l-control>
  </l-map>
</template>

<script>
import L from 'leaflet'
import { LMap, LControl, LTileLayer } from 'vue2-leaflet'
import 'leaflet-draw/dist/leaflet.draw-src'

import 'leaflet-draw/dist/leaflet.draw.css'

import { mdiRectangle } from '@mdi/js'

export default {
  components: {
    LMap,
    LControl,
    LTileLayer,
  },

  data() {
    return {
      zoom: 14,
      center: [45.34481892275193, 14.44775250818104],
      bounds: null,

      leafletOptions: {
        minZoom: 13,
        maxZoom: 15,
        maxBounds: L.latLngBounds(L.latLng(45.451196, 14.287033), L.latLng(45.267372, 14.581686)),
      },

      oldLayer: undefined,

      isDrawing: false,
      drawControl: null,

      icons: {
        mdiRectangle,
      },
    }
  },

  async mounted() {
    await this.$nextTick()

    /* eslint-disable-next-line no-underscore-dangle */
    this.$refs.map.mapObject._onResize()

    this.$refs.map.mapObject.on(L.Draw.Event.DRAWSTART, () => this.drawStart(this.oldLayer))
    this.$refs.map.mapObject.on(L.Draw.Event.CREATED, e => this.drawEnd(e.layer))

    const editableLayers = new L.FeatureGroup()
    this.$refs.map.mapObject.addLayer(editableLayers)

    const options = {
      position: 'topright',
      draw: {
        polygon: false,
        marker: false,
        circle: false,
        polyline: false,
        circlemarker: false,
        rectangle: {
          shapeOptions: {
            clickable: false,
          },
        },
      },
      edit: {
        featureGroup: editableLayers, // REQUIRED!!
        remove: false,
        edit: false,
      },
    }

    this.drawControl = new L.Draw.Rectangle(this.$refs.map.mapObject, options)
  },

  beforeUnmount() {
    this?.drawControl?.disable()
    this?.drawControl?.remove()

    this.$refs.map.mapObject.off(L.Draw.Event.DRAWSTART)
    this.$refs.map.mapObject.off(L.Draw.Event.CREATED)
  },

  methods: {
    zoomUpdated(zoom) {
      this.zoom = zoom
    },

    centerUpdated(center) {
      this.center = center
    },

    boundsUpdated(bounds) {
      this.bounds = bounds
    },

    drawStart(layer) {
      this.isDrawing = true
      if (layer) this.$refs.map.mapObject.removeLayer(layer)
    },

    drawEnd(layer) {
      this.isDrawing = false

      this.oldLayer = layer
      this.$refs.map.mapObject.addLayer(layer)

      /* eslint-disable-next-line no-underscore-dangle */
      this.$emit('selectionCompleted', layer._bounds)
    },

    toggleDraw() {
      /* eslint-disable-next-line no-underscore-dangle */
      if (this.drawControl._enabled) {
        this.drawControl.disable()
        this.isDrawing = false
      } else {
        this.drawControl.enable()
        this.isDrawing = true
      }
    },
  },
}
</script>

<style>
.rectangle-color {
  color: #fff !important;
}
</style>
