var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('l-map',{ref:"geohashMap",staticClass:"map-styling",staticStyle:{"height":"800px","width":"100%"},attrs:{"center":_vm.center,"bounds":_vm.selectionBounds,"options":{
      zoomControl: false,
      boxZoom: false,
      doubleClickZoom: false,
      scrollWheelZoom: false,
      touchZoom: false,
      dragging: false,
    }},on:{"click":_vm.selectGeohashCell}},[_c('l-tile-layer',{attrs:{"url":_vm.$tileService.url,"attribution":_vm.$tileService.attribution}}),_c('l-rectangle',{attrs:{"bounds":_vm.selectionBounds,"l-style":_vm.rectangleStyle}}),_vm._l((_vm.geohashCells),function(geohashCell){return _c('l-rectangle',{key:geohashCell.geohash,attrs:{"bounds":[
        geohashCell.southWest,
        geohashCell.northEast
      ],"l-style":_vm.isGeohashSelected(geohashCell.geohash) ? _vm.selectedGeohashCellStyle : _vm.geohashCellStyle}})})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }