<template>
  <!-- <div v-if="!$apollo.queries.geohashCells.loading"> -->
  <div>
    <!-- <div
      v-if="!cellDataAvailable"
      class="pb-13 pl-1"
    >
      Data not available in the selected range or the selection area is too big.
    </div> -->

    <l-map
      ref="geohashMap"
      style="height: 800px; width: 100%"
      :center="center"
      :bounds="selectionBounds"
      :options="{
        zoomControl: false,
        boxZoom: false,
        doubleClickZoom: false,
        scrollWheelZoom: false,
        touchZoom: false,
        dragging: false,
      }"
      class="map-styling"
      @click="selectGeohashCell"
    >
      <l-tile-layer
        :url="$tileService.url"
        :attribution="$tileService.attribution"
      ></l-tile-layer>

      <l-rectangle
        :bounds="selectionBounds"
        :l-style="rectangleStyle"
      ></l-rectangle>

      <l-rectangle
        v-for="geohashCell in geohashCells"
        :key="geohashCell.geohash"
        :bounds="[
          geohashCell.southWest,
          geohashCell.northEast
        ]"
        :l-style="isGeohashSelected(geohashCell.geohash) ? selectedGeohashCellStyle : geohashCellStyle"
      ></l-rectangle>
    </l-map>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */

import { LMap, LTileLayer, LRectangle } from 'vue2-leaflet'
import L from 'leaflet'
import shape2geohash from 'shape2geohash'
import latlonGeohash from 'latlon-geohash'

// import gql from 'graphql-tag'

export default {
  components: {
    LMap,
    LTileLayer,
    LRectangle,
  },

  props: {
    selectionBounds: {
      type: Object,
      required: true,
      default: null,
    },
  },

  data() {
    return {
      zoom: 13,
      center: [45.34481892275193, 14.44775250818104],

      geohashCells: [],
      selectedGeohashCells: [],
      cellDataAvailable: true,

      rectangleStyle: {
        fillColor: 'green',
        fillOpacity: 0.15,
        weight: 0,
      },

      geohashCellStyle: {
        color: 'grey',
        fillColor: 'grey',
        weight: 2,
      },

      selectedGeohashCellStyle: {
        color: 'green',
        fillColor: 'green',
        fillOpacity: 0.35,
        weight: 3,
      },
    }
  },

  // apollo: {
  //   geohashCells: {
  //     query: gql`
  //       query ($boundingBox: BoundingBoxInput!) {
  //         geohashCells(boundingBox: $boundingBox) {
  //           geohash
  //           southWest {
  //             lat
  //             lng
  //           }
  //           northEast {
  //             lat
  //             lng
  //           }
  //         }
  //       }
  //     `,
  //     variables() {
  //       return {
  //         boundingBox: {
  //           /* eslint-disable-next-line no-underscore-dangle */
  //           southWest: this.selectionBounds._southWest,

  //           /* eslint-disable-next-line no-underscore-dangle */
  //           northEast: this.selectionBounds._northEast,
  //         },
  //       }
  //     },
  //     result() {
  //       this.geohashCellsInSelectionBounds()
  //     },
  //     fetchPolicy: 'no-cache',
  //   },
  // },

  watch: {
    // selectionBounds: {
    //   handler() {
    //     this.selectedGeohashCells = []
    //     this.$apollo.queries.geohashCells.refetch()
    //   },
    // },

    selectionBounds: {
      handler() {
        this.geohashCells = []
        this.selectedGeohashCells = []
        this.geohashCellsInArea()
      },
    },

    selectedGeohashCells: {
      handler() {
        this.selectedGeohashCellsChanged()
      },
    },
  },

  async mounted() {
    this.geohashCellsInArea()
  },

  methods: {
    // geohashCellsInSelectionBounds() {
    //   if (this.geohashCells?.length > 0) {
    //     /* eslint-disable-next-line no-underscore-dangle */
    //     const selectionArea = L.latLngBounds(this.selectionBounds._southWest, this.selectionBounds._northEast)

    //     this.geohashCells.forEach(cell => {
    //       const northEast = L.latLng(cell.northEast)
    //       const southWest = L.latLng(cell.southWest)
    //       const northWest = L.latLng(cell.southWest.lat, cell.northEast.lng)
    //       const southEast = L.latLng(cell.northEast.lat, cell.southWest.lng)

    //       if (
    //         selectionArea.contains(northEast) &&
    //         selectionArea.contains(southWest) &&
    //         selectionArea.contains(northWest) &&
    //         selectionArea.contains(southEast)
    //       ) {
    //         this.selectedGeohashCells.push(cell.geohash)
    //       }

    //       this.cellDataAvailable = true
    //     })
    //   } else {
    //     this.cellDataAvailable = false
    //   }

    //   if (this.selectedGeohashCells.length === 0) this.selectedGeohashCellsChanged()
    // },

    geohashCellsInArea() {
      shape2geohash(
        {
          type: 'Polygon',
          coordinates: [
            [
              [this.selectionBounds._northEast.lng, this.selectionBounds._northEast.lat], // [long, lat]
              [this.selectionBounds._southWest.lng, this.selectionBounds._northEast.lat],
              [this.selectionBounds._southWest.lng, this.selectionBounds._southWest.lat],
              [this.selectionBounds._northEast.lng, this.selectionBounds._southWest.lat],
              [this.selectionBounds._northEast.lng, this.selectionBounds._northEast.lat], // make sure the last coordinate is equal to the first one
            ],
          ],
        },
        {
          precision: 7,
        },
      ).then(geohashes => {
        geohashes.forEach(geohashCell => {
          const bounds = latlonGeohash.bounds(geohashCell)
          this.geohashCells.push({
            southWest: bounds.sw,
            northEast: bounds.ne,
            geohash: geohashCell,
          })

          this.selectedGeohashCells.push(geohashCell)
        })
      })
    },

    selectGeohashCell(event) {
      const point = L.latLng(event.latlng)

      this.geohashCells.forEach(cell => {
        const southWestPoint = L.latLng(cell.southWest)
        const northEastPoint = L.latLng(cell.northEast)

        const geohashArea = L.latLngBounds(southWestPoint, northEastPoint)

        if (geohashArea.contains(point)) {
          if (this.isGeohashSelected(cell.geohash)) {
            const index = this.selectedGeohashCells.indexOf(cell.geohash)
            this.selectedGeohashCells.splice(index, 1)
          } else {
            this.selectedGeohashCells.push(cell.geohash)
          }
        }
      })
    },

    isGeohashSelected(geohash) {
      return this.selectedGeohashCells.includes(geohash)
    },

    selectedGeohashCellsChanged() {
      // this.$emit('geohashCellsSelected', this.selectedGeohashCells, this.$apollo.queries.geohashCells.loading)
      this.$emit('geohashCellsSelected', this.selectedGeohashCells)
    },
  },
}
</script>
