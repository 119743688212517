<template>
  <v-stepper v-model="activeTab">
    <v-stepper-header>
      <v-stepper-step
        :complete="activeTab > 1"
        step="1"
      >
        Area selection
      </v-stepper-step>

      <v-divider />

      <v-stepper-step
        :complete="activeTab > 2"
        step="2"
      >
        Geohash cells
      </v-stepper-step>

      <v-divider />

      <v-stepper-step step="3">
        Filters
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <!-- FIRST STEP -->
      <v-stepper-content step="1">
        <v-lazy>
          <div>
            <LeafletSelectionMap
              class="mb-2"
              @selectionCompleted="areaSelected"
            />

            <div class="d-flex justify-end">
              <v-btn
                color="primary"
                :disabled="!selectionBounds"
                @click="activeTab = 2"
              >
                Continue
              </v-btn>
            </div>
          </div>
        </v-lazy>
      </v-stepper-content>

      <!-- SECOND STEP -->
      <v-stepper-content step="2">
        <!-- <v-overlay :value="cellsLoading">
          <v-progress-circular indeterminate></v-progress-circular>
        </v-overlay> -->
        <v-lazy>
          <div v-if="selectionBounds">
            <LeafletGeohashCellsMap
              class="mb-2"
              :selection-bounds="selectionBounds"
              @geohashCellsSelected="changeSelectedGeohashCells"
            />

            <div class="d-flex justify-space-between">
              <v-btn
                color="primary"
                @click="activeTab = 1"
              >
                Back
              </v-btn>
              <v-btn
                color="primary"
                :disabled="selectedGeohashCells.length === 0"
                @click="activeTab = 3"
              >
                Continue
              </v-btn>
            </div>
          </div>
        </v-lazy>
      </v-stepper-content>

      <!-- THIRD STEP -->
      <v-stepper-content step="3">
        <v-lazy>
          <div class="px-4">
            <ReportType
              class="mb-3"
              @selectedReportTypeChanged="changeReportType"
            />

            <div class="mb-3">
              <v-row>
                <v-col>
                  <div class="pl-1 mb-1">
                    Date Range
                  </div>
                  <DatePicker @datesSelected="changeSelectedDate" />

                  <div
                    v-if="!$v.selectedDates.required || !$v.selectedDates.minLength"
                    class="validationError pl-2 pt-1"
                  >
                    From and until dates are required.
                  </div>
                </v-col>

                <v-col v-if="selectedReportType == 'HOURLY'">
                  <div class="pl-1 mb-1">
                    Time Range
                  </div>
                  <TimePicker
                    v-if="selectedReportType == 'HOURLY'"
                    class="pb-8"
                    :dates="selectedDates"
                    :same-day-selected="sameDaySelected"
                    hide-details
                    @timesSelected="changeSelectedTime"
                  />

                  <div
                    v-if="!$v.selectedTimes.required || !$v.selectedTimes.minLength"
                    class="validationError pl-2 pt-1"
                  >
                    From time and until time are required.
                  </div>
                </v-col>
              </v-row>
            </div>

            <AgeGenderTariffFilters
              :age-error-messages="!$v.selectedAgeGroups.required ? ['Age groups are required.'] : []"
              @ageGenderTariffFilterChanged="changeAgeGenderTariffFilter"
            />

            <div class="mb-6">
              <CountryFilters
                :selected-from="fromFormatted"
                :selected-until="untilFormatted"
                :selected-time-range="'QUARTERLY'"
                @countrySelected="changeCountryFilter"
              />
            </div>

            <div class="d-flex justify-space-between">
              <v-btn
                color="primary"
                @click="activeTab = 2"
              >
                Back
              </v-btn>

              <div>
                <v-progress-circular
                  v-if="$apollo.queries.createDonteReport.loading"
                  indeterminate
                  color="primary"
                  class="mr-2"
                ></v-progress-circular>
                <v-btn
                  color="primary"
                  :disabled="$apollo.queries.createDonteReport.loading || $v.$invalid"
                  @click="generateReport"
                >
                  Generate report
                </v-btn>
              </div>
            </div>
          </div>
        </v-lazy>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
/* eslint-disable */

import LeafletSelectionMap from '../components/donte/LeafletSelectionMap.vue'
import LeafletGeohashCellsMap from '../components/donte/LeafletGeohashCellsMap.vue'

import ReportType from '../components/heatmaps-shared/ReportType.vue'
import DatePicker from '../components/donte/DatePicker.vue'
import TimePicker from '../components/donte/TimePicker.vue'
import AgeGenderTariffFilters from '../components/teddy/AgeGenderTariffFilters.vue'
import CountryFilters from '../components/teddy/CountryFilters.vue'

import gql from 'graphql-tag'

import { required, requiredIf, minLength } from 'vuelidate/lib/validators'

export default {
  components: {
    LeafletSelectionMap,
    LeafletGeohashCellsMap,

    ReportType,
    DatePicker,
    TimePicker,
    AgeGenderTariffFilters,
    CountryFilters,
  },

  apollo: {
    createDonteReport: {
      query: gql`
        query (
          $userId: ID
          $reportType: ReportType!
          $from: DateTimeTz
          $until: DateTimeTz
          $geohashCells: [String!]!
          $selectionBounds: SelectionBoundsInput
          $ageGroups: [AgeGroup]
          $genders: [Gender]
          $tariffs: [Tariff]
          $countries: [String]
        ) {
          createDonteReport(
            userId: $userId
            reportType: $reportType
            from: $from
            until: $until
            geohashCells: $geohashCells
            selectionBounds: $selectionBounds
            ageGroups: $ageGroups
            genders: $genders
            tariffs: $tariffs
            countries: $countries
          ) {
            id
          }
        }
      `,
      variables() {
        return {
          userId: localStorage?.userId,
          reportType: this.selectedReportType,
          from: this.fromFormatted,
          until: this.untilFormatted,
          geohashCells: this.selectedGeohashCells,
          selectionBounds: this.selectionBounds,
          genders: this.selectedGenders,
          ageGroups: this.selectedAgeGroups,
          tariffs: this.selectedTariffs,
          countries: this.selectedCountries,
        }
      },
      result(res) {
        if (res.error) {
          this.$apollo.queries.createDonteReport.skip = true
        } else this.$router.push({ name: 'donteReports' })
      },
      fetchPolicy: 'network-only',
      skip: true,
    },
  },

  computed: {
    fromFormatted() {
      return this.formatFromAndUntil(0, this.selectedDates, this.selectedTimes, this.selectedReportType)
    },

    untilFormatted() {
      return this.formatFromAndUntil(1, this.selectedDates, this.selectedTimes, this.selectedReportType)
    },
  },

  data() {
    return {
      activeTab: 1,

      selectionBounds: undefined,
      selectedDates: [],
      selectedTimes: [],
      selectedReportType: 'HOURLY',
      selectedGeohashCells: [],
      selectedAgeGroups: 'AG_ALL',
      selectedGenders: 'ALL',
      selectedTariffs: 'ALL',
      selectedCountries: [],

      sameDaySelected: false,

      // cellsLoading: true,
    }
  },

  validations: {
    selectedAgeGroups: {
      required,
    },
    selectedDates: {
      required,
      minLength: minLength(2),
    },
    selectedTimes: {
      required: requiredIf(function () {
        if (this.selectedReportType == 'HOURLY') return true
        else return false
      }),
      minLength: minLength(2),
    },
  },

  methods: {
    generateReport() {
      if (!this.$v.$invalid) {
        this.$apollo.queries.createDonteReport.skip = false
      } else {
        this.$v.$touch()
      }
    },

    areaSelected(bounds, zoom, center) {
      this.selectionBounds = bounds
    },

    changeSelectedGeohashCells(newCells) {
      this.selectedGeohashCells = newCells
    },

    // changeSelectedGeohashCells(newCells, cellsLoading) {
    //   this.selectedGeohashCells = newCells
    //   this.cellsLoading = cellsLoading
    // },

    changeReportType(newReportType) {
      if (newReportType == 'DAILY') this.selectedTimes = []
      this.selectedReportType = newReportType
    },

    changeSelectedDate(dates) {
      if (dates[0] == dates[1]) this.sameDaySelected = true
      else this.sameDaySelected = false

      this.selectedDates = dates
    },

    changeSelectedTime(times) {
      this.selectedTimes = times
    },

    changeAgeGenderTariffFilter(age, gender, tariff) {
      this.selectedAgeGroups = age
      this.selectedGenders = gender
      this.selectedTariffs = tariff
    },

    changeCountryFilter(countries) {
      this.selectedCountries = countries
    },

    formatFromAndUntil(index, selectedDates, selectedTimes, selectedReportType) {
      if (!selectedTimes.length && selectedReportType !== 'DAILY') return null

      if (selectedDates.length > 1) {
        if (selectedReportType == 'DAILY') {
          selectedTimes = ['00:00', '24:00']
        }

        return this.$moment(`${selectedDates[index]} ${selectedTimes[index]}`, 'YYYY-MM-DD HH:mm').format(
          'YYYY-MM-DDTHH:mm:ssZ',
        )
      }

      return null
    },
  },
}
</script>
